<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12" md="6" offset-md="3" class="text-center">
          <h2 class="text-primary font-weight-bold text-center">
            جستجو کن. شاید پیداش کردی
          </h2>
          <img
            src="@/assets/images/divider.png"
            alt="divider"
            class="my-4"
            width="46"
          />
          <form @submit.stop.prevent>
            <div
              class="row no-gutters align-items-center border rounded-pill py-1 px-1 mx-auto mt-2"
            >
              <div class="col-auto">
                <b-icon icon="search" color="gray" class="ms-5"></b-icon>
              </div>
              <!--end of col-->
              <div class="col mx-2">
                <input
                  class="form-control border-0"
                  type="search"
                  placeholder="دنبال چه چیزی میگردید؟"
                />
              </div>
              <!--end of col-->
              <div class="col-auto">
                <button
                  class="btn btn-secondary text-white rounded-pill py-4 px-6"
                  type="submit"
                >
                  جستجو
                </button>
              </div>
              <!--end of col-->
            </div>
          </form>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12">
          <v-chip-group mandatory active-class="text-white bg-secondary">
            <v-chip v-for="category in categories" :key="category.id">
              {{ category.title }}
            </v-chip>
          </v-chip-group>
        </b-col>

        <b-col cols="12" md="12" class="text-center">
          <b-row>
            <b-col
              cols="12"
              md="3"
              v-for="blog in posts"
              :key="blog.id"
              class="category-item"
            >
              <img
                :src="getImage(blog.pic)"
                alt="divider"
                class="curve w-100 shadow"
              />

              <b-card class="curve mt-2 shadow">
                <v-chip-group column>
                  <v-chip
                    v-for="category in blog.categories"
                    :key="category.id"
                  >
                    {{ category.title }}
                  </v-chip>
                </v-chip-group>

                <h6 class="my-2 font-weight-bold text-medium">
                  {{ blog.title }}
                </h6>
                <p class="my-2 font-weight-normal text-small max-3-line">
                  {{ blog.desc }}
                </p>

                <hr />

                <div class="d-flex align-items-center">
                  <v-avatar size="40">
                    <v-img
                      :src="getImage(blog.authorPic)"
                      :lazy-src="require('@/assets/images/profile.jpg')"
                      alt="author"
                      class="rounded"
                    >
                    </v-img>
                  </v-avatar>
                  <span class="ms-2 text-small">{{ blog.author }}</span>
                  <div class="ms-auto">
                    <span class="text-muted"
                      ><span class="text-small">{{ blog.commentCount }}</span
                      ><b-icon class="ms-1" icon="chat-dots-fill"></b-icon
                    ></span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="12"
          md="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-icon icon="dot" color="#FF774E" font-scale="1.5"></b-icon>
          <b-link class="">مشاهده بیشتر</b-link>
          <b-icon icon="dot" color="#FF774E" font-scale="1.5"></b-icon>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col cols="12" md="12" class="mt-4">
          <div class="wh-40 shadow rounded-lg my-2 text-center d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/ic-microphone.png"
              alt="microphone"
              width="12"
            />
          </div>
          <h2 class="my-4 text-primary font-weight-bold">
            پادکست ها
          </h2>
          <img
            src="@/assets/images/divider.png"
            alt="divider"
            class="mb-4"
            width="46"
          />
        </b-col>
        <b-col cols="12" md="6">
          <b-card class="py-0 my-0 curve position-relative">
            <b-card-body>
              <div
                class="content-items"
                v-for="podcast in podcasts"
                :key="podcast.id"
              >
                <b-media vertical-align="center">
                  <template #aside>
                    <b-img
                      :src="getImage(podcast.pic)"
                      width="80"
                      alt="placeholder"
                      class="me-3 rounded"
                    ></b-img>
                  </template>
                  <h6 class="mt-0 mb-1 font-weight-bold text-medium">
                    {{ podcast.title }}
                  </h6>
                  <p class="mb-0 text-muted font-weight-normal text-small">
                    {{ podcast.author }}
                  </p>
                  <span class="small text-black-50 font-weight-light text-small"
                    ><b-icon icon="clock" class="me-2"></b-icon
                    >{{ podcast.createTime }}</span
                  >
                </b-media>
                <hr />
              </div>
            </b-card-body>
            <b-button
              class="btn btn-secondary curve position-absolute text-white px-5 py-2"
              style="bottom: -1em; left:2em"
            >
              بیشتر
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" class="d-flex align-items-center">
          <div>
            <div class="podcast-cover position-relative">
              <img
                src="@/assets/images/podcast-cover.png"
                alt="img"
                class="curve w-100 h-auto shadow"
              />

              <div
                class="position-absolute podcast-cover-content d-flex flex-column align-items-center justify-content-center"
              >
                <p class="text-medium text-white text-center">
                  آیا می توان مشکل فرزند آوری را به طور کامل بر طرف کرد؟
                </p>
                <p class="text-small text-white text-center">محمد امین قاسمی</p>
                <p class="text-x-small text-white text-center">‏25 دقیقه</p>
                <div class="">
                  <v-btn elevation="2" fab small color="bg-black-20">
                    <img src="@/assets/images/media-next.png" alt="prev"
                  /></v-btn>
                  <v-btn elevation="2" fab class="mx-4" color="bg-black-50">
                    <img
                      src="@/assets/images/media-play.png"
                      alt="play"
                      width="24"
                    />
                  </v-btn>
                  <v-btn elevation="2" fab small color="bg-black-20">
                    <img src="@/assets/images/media-prev.png" alt="next" />
                  </v-btn>
                </div>
              </div>
            </div>

            <b-card class="curve mt-2 shadow">
              <h6 class="my-2 font-weight-bold text-medium text-secondary">
                آیا می توان مشکل فرزند آوری را به طور کامل بر طرف کرد؟
              </h6>
              <p class="my-2 font-weight-normal text-small max-3-line">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
                استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
                نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
                کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
                جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری
              </p>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col cols="12" md="12" class="mt-4">
          <div class="wh-40 shadow rounded-lg my-2 text-center d-flex align-items-center justify-content-center">
            <img
              src="@/assets/images/ic-video-camera.png"
              alt="video"
              width="18"
            />
          </div>
          <h2 class="my-4 text-primary font-weight-bold">
            ویدئو ها
          </h2>
          <img
            src="@/assets/images/divider.png"
            alt="divider"
            class="mb-4"
            width="46"
          />
        </b-col>
        <b-col cols="12" md="6">
          <b-card class="py-0 my-0 curve position-relative">
            <b-card-body>
              <div
                class="content-items"
                v-for="video in videos"
                :key="video.id"
              >
                <b-media vertical-align="center">
                  <template #aside>
                    <b-img
                      :src="getImage(video.pic)"
                      width="80"
                      alt="placeholder"
                      class="me-3 rounded"
                    ></b-img>
                  </template>
                  <h6 class="mt-0 mb-1 font-weight-bold text-medium">
                    {{ video.title }}
                  </h6>
                  <p class="mb-0 text-muted font-weight-normal text-small">
                    {{ video.author }}
                  </p>
                  <span class="small text-black-50 font-weight-light text-small"
                    ><b-icon icon="clock" class="me-2"></b-icon
                    >{{ video.createTime }}</span
                  >
                </b-media>
                <hr />
              </div>
            </b-card-body>
            <b-button
              class="btn btn-secondary curve position-absolute text-white px-5 py-2"
              style="bottom: -1em; left:2em"
            >
              بیشتر
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" class="d-flex align-items-center">
          <div>
            <div class="podcast-cover position-relative">
              <img
                src="@/assets/images/video-cover.png"
                alt="img"
                class="curve w-100 h-auto shadow"
              />

              <div
                class="position-absolute podcast-cover-content d-flex flex-column align-items-center justify-content-center"
              >
                
              </div>
            </div>

            <b-card class="curve mt-2 shadow">
              <h6 class="my-2 font-weight-bold text-medium text-secondary">
                آیا می توان مشکل فرزند آوری را به طور کامل بر طرف کرد؟
              </h6>
              <p class="my-2 font-weight-normal text-small max-3-line">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
                استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
                نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
                کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
                جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری
              </p>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        { id: 0, title: "همه دسته بندی ها" },
        { id: 1, title: "مهارت های زندگی" },
        { id: 2, title: "روان پزشكي و دارو درماني" },
        { id: 3, title: "روابط بین فردی" },
        { id: 4, title: "ترک اعتیاد" },
        { id: 5, title: "مشاوره شغلی و تحصیلی" },
        { id: 6, title: "مشاوره ازدواج و پیش از ازدواج" },
        { id: 7, title: "مشاوره فردی" },
        { id: 8, title: "روابط بین فردی 2" },
        { id: 9, title: "ترک اعتیاد 2" },
        { id: 10, title: "مشاوره شغلی و تحصیلی 2" },
      ],

      posts: [
        {
          id: 0,
          pic: "blog-5.png",
          title: "چشم در مقابل چشم",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [{ id: 0, title: "مادر، فرزند و فرزند پروری" }],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
        {
          id: 1,
          pic: "blog-6.png",
          title: "آیا می توان مشکل فرزند آوری با به طور کامل بر طرف کرد؟",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [{ id: 0, title: "مادر، فرزند و فرزند پروری" }],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
        {
          id: 2,
          pic: "blog-7.png",
          title: "چشم در مقابل چشم",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [
            { id: 0, title: "مادر، فرزند" },
            { id: 1, title: "فرزند پروری" },
          ],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
        {
          id: 3,
          pic: "blog-8.png",
          title: "چگونه زندگی خود را سازماندهی کنیم؟",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [{ id: 0, title: "مادر، فرزند و فرزند پروری" }],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
        {
          id: 4,
          pic: "blog-1.png",
          title: "چگونه زندگی خود را سازماندهی کنیم؟",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [{ id: 0, title: "مادر، فرزند و فرزند پروری" }],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
        {
          id: 5,
          pic: "blog-2.png",
          title: "چگونه زندگی خود را سازماندهی کنیم؟",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [{ id: 0, title: "مادر، فرزند و فرزند پروری" }],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
        {
          id: 6,
          pic: "blog-3.png",
          title: "چگونه زندگی خود را سازماندهی کنیم؟",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [{ id: 0, title: "مادر، فرزند و فرزند پروری" }],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
        {
          id: 7,
          pic: "blog-4.png",
          title: "چگونه زندگی خود را سازماندهی کنیم؟",
          desc:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است",
          categories: [{ id: 0, title: "مادر، فرزند و فرزند پروری" }],
          author: "دکتر محمد امین قاسمی",
          authorPic: "profile.jpg",
          commentCount: "30",
        },
      ],

      podcasts: [
        {
          id: 0,
          pic: "podcast-1.png",
          title: "چگونه زندگی خود را سازماندهی کنیم؟",
          author: "دکتر محمد امین قاسمی",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
        {
          id: 1,
          pic: "podcast-2.png",
          title: "آیا مصرف مواد مخدر تاثیر مستقیمی بر روی زندگی زناشویی دارد؟",
          author: "دکتر محسن رضایی",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
        {
          id: 2,
          pic: "podcast-3.png",
          title:
            "چرا نمی توانیم آن طور که می خواهیم زندگی خود را برنامه ریزی کنیم؟",
          author: "دکتر محمود احمدی نژاد",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
        {
          id: 3,
          pic: "podcast-3.png",
          title: "چگونه می توانیم بدترین مدیریت را داشته باشیم؟",
          author: "دکتر حسن روحانی",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
      ],

      videos: [
        {
          id: 0,
          pic: "video-1.png",
          title: "چگونه زندگی خود را سازماندهی کنیم؟",
          author: "دکتر محمد امین قاسمی",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
        {
          id: 1,
          pic: "video-2.png",
          title: "آیا مصرف مواد مخدر تاثیر مستقیمی بر روی زندگی زناشویی دارد؟",
          author: "دکتر محسن رضایی",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
        {
          id: 2,
          pic: "video-3.png",
          title:
            "چرا نمی توانیم آن طور که می خواهیم زندگی خود را برنامه ریزی کنیم؟",
          author: "دکتر محمود احمدی نژاد",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
        {
          id: 3,
          pic: "video-3.png",
          title: "چگونه می توانیم بدترین مدیریت را داشته باشیم؟",
          author: "دکتر حسن روحانی",
          createTime: "‏1 ساعت و 20 دقیقه",
        },
      ],
    };
  },
  methods: {
    getImage(src) {
      return require(`@/assets/images/${src}`);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
